import moment from "moment";

class Collab {
    constructor(data) {
        // TODO remove attrs
        this.attrs = this.data = data;

        this.start_at = data.start_at ? new moment(data.start_at).utc() : null
        this.end_at = data.end_at ? new moment(data.end_at).utc() : null
        this.created_at = data.created_at ? new moment(data.created_at) : null
        this.updated_at = data.updated_at ? new moment(data.updated_at) : null
        this.closed_at = data.closed_at ? new moment(data.closed_at) : null
        this.canceled_at = data.canceled_at ? new moment(data.canceled_at) : null
    }

    /**
     * Determine if a collaboration is for a specific date
     *
     * @returns {Boolean}
     */
    isSpecificDay() {
        let sameDay = false;

        if (this.start_at && this.end_at) {
            sameDay = this.start_at.isSame(this.end_at, 'day')
        }

        return sameDay;
    }

    isPrivateType() {
        return this.data.type === 'private';
    }

    isPublicType() {
        return this.data.type === 'public';
    }

    /**
     * @returns {Boolean}
     */
    isFoodieMeeting() {
        return this.attrs.foodies_meeting;
    }

    isActive() {
        return this.attrs.status === 'active';
    }

    isClosed() {
        return this.attrs.status === 'closed';
    }

    isCanceled() {
        return this.attrs.status === 'canceled';
    }

    getStatusString() {
        return Collab.statusMap(this.attrs.status)
    }

    getTypeString() {
        return Collab.typeMap(this.attrs.type)
    }

    getRestaurantName() {
        if (this.attrs.restaurant) {
            return this.attrs.restaurant.name
        }

        if (this.attrs.slim_restaurant) {
            return this.attrs.slim_restaurant.name
        }
    }

    getRestaurantPicture() {
        let src = null;

        if (this.attrs.restaurant) {
            src = this.attrs.restaurant.profile_picture ?? src;
        }

        if (this.attrs.slim_restaurant) {
            src = this.attrs.slim_restaurant.profile_picture ?? src
        }

        return src;
    }

    getDiscountValueString() {
        if (this.data.discount_value <= 0) {
            return 'Sin descuento'
        }

        if (this.data.discount_type === 'prc') {
            return this.data.discount_value + '%';
        } else if (this.data.discount_type === 'fixed_e2') {
            return (this.data.discount_value / 100.0) + '€';
        }
    }

    static typeMap(type) {
        const map = {
            'all': 'Todos',
            'public': 'Publica',
            'private': 'Privada',
        };

        return map[type] ?? type;
    }

    static statusMap(status) {
        const map = {
            'all': 'Todos',
            'active': 'Activo',
            'canceled': 'Cancelado',
            'closed': 'Cerrado',
        };

        return map[status] ?? status;
    }
}

export default Collab;