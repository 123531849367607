<template>
  <div class="p-grid p-fluid">
    <Toast/>

    <!-- Collab Data -->
    <div class="card p-col p-mr-1">
      <div class="p-col-3">
        <a href="#" @click.prevent="backToIndex">Ir a lista</a>
      </div>

      <div class="p-col-6">
        <div>
          <div class="p-mr-1 p-d-inline">
            <label style="font-size: 24px">Colaboración {{ collab?.getTypeString() }} <a href="#">{{
                collab?.data.code
              }}</a></label>
          </div>

          <div v-if="collab?.isActive()" class="p-d-inline">
            <Chip label="Activa" class="p-mr-2 p-mb-2 active-collab"/>
          </div>

          <div v-else-if="collab?.isCanceled()" class="p-d-inline">
            <Chip label="Cancelada" class="p-mr-2 p-mb-2 canceled-collab"/>
          </div>

          <div v-else-if="collab?.isClosed()" class="p-d-inline">
            <Chip label="Cerrada" class="p-mr-2 p-mb-2 closed-collab"/>
          </div>
        </div>

        <div v-if="collab?.isSpecificDay()" style="font-size: 16px" class="p-mb-3">
          <label>Para la fecha: </label>
          <strong>{{ collab?.start_at.format('Y-MM-DD HH:mm') }}</strong>
        </div>

        <div v-else style="font-size: 16px" class="p-mb-3">
          <label>Fechas: </label>
          Desde {{ collab?.start_at.format('Y-MM-DD') }} al {{ collab?.end_at.format('Y-MM-DD') }}
        </div>

        <div>
          <span class="p-text-secondary p-mb-2 p-d-block" style="font-size: 18px">Seleccione tipo de fecha</span>
          <div class="p-field-radiobutton">
            <RadioButton id="same_day" name="same_day" value="same_day" v-model="dateType"/>
            <label for="same_day">Un día</label>
          </div>

          <div class="p-field-radiobutton">
            <RadioButton id="range" name="range" value="range" v-model="dateType"/>
            <label for="range">Rango</label>
          </div>
        </div>

        <div class="p-fluid p-grid p-formgrid">
          <div v-if="dateType === 'same_day'" class="p-field p-col-12">
            <label for="time24">Fecha</label>
            <Calendar id="time24" v-model="same_day_date" :showTime="true" :showSeconds="false" :showIcon="true"
                      selectionMode="single" dateFormat="yy/mm/dd"/>
          </div>

          <div v-if="dateType === 'range'" class="p-field p-col-12">
            <label for="time24">Desde / Hasta</label>
            <Calendar id="time24" v-model="range_date" :showTime="true" :showSeconds="false" :showIcon="true"
                      selectionMode="range" dateFormat="yy/mm/dd"/>
          </div>

          <div class="p-field p-col-12">
            <label>Límite de seguidores</label>
            <InputNumber v-model="updated.min_ig_followers"/>
          </div>

          <div class="p-field p-col-6">
            <label>Tipo de descuento</label>
            <Dropdown
                v-model="updated.discount_type"
                :options="[{ label: 'Porcentaje', value: 'prc' }, { label: 'Descuento', value: 'fixed_e2'}]"
                optionLabel="label"
                optionValue="value"
                placeholder="Select a City" />
          </div>

          <div v-if="updated.discount_type === 'prc'" class="p-field p-col-6">
            <label>Descuento</label>
            <InputNumber v-model="updated.discount_value" :min="10" :max="100"/>
          </div>
          <div v-else class="p-field p-col-6">
            <label>Crédito</label>
            <InputNumber v-model="updated.discount_value" mode="currency" currency="EUR" :min="0"/>
          </div>

          <div v-if="collab?.attrs?.type === 'public'" class="p-field p-col-12">
            <label>Límite de usos</label>
            <InputNumber v-model="updated.uses_limit"/>
          </div>

          <!--          <div class="p-field p-col-12">-->
<!--            <label>Límite de acompañantes</label>-->
<!--            <InputNumber v-model="updated.companions_limit"/>-->
<!--          </div>-->

        </div>
      </div>

      <div class="p-col-6 p-d-flex">
        <div class="p-col-6 ">
          <Button label="Actualizar" @click="update"/>
        </div>
        <div class="p-col-6">
          <Button label="Cancelar" style="background-color: #e73232; border-color: #e73232 !important" @click="$router.go(-1)"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Collab from "src/repositories/models/Collab";
import moment from "moment";

export default {
  data() {
    return {
      collab: null,
      dateType: "range",
      same_day_date: null,
      range_date: null,
      updated: {
        start_at: null,
        end_at: null,
      }
    }
  },
  methods: {
    async getCollab() {
      try {
        const response = await this.$repository.collaborations.show(
            this.$route.params.id, this.query
        )

        this.collab = new Collab(response.data)
        this.dateType = this.collab.isSpecificDay() ? 'same_day' : 'range'
        this.start_at = this.collab.start_at;
        this.end_at = this.collab.end_at;
        this.updated.min_ig_followers = this.collab.attrs.min_ig_followers
        this.updated.uses_limit = this.collab.attrs.uses_limit
        this.updated.discount_type = this.collab.attrs.discount_type

        if(this.collab.attrs.discount_type === 'fixed_e2') {
          this.updated.discount_value = this.collab.attrs.discount_value / 100
        } else {
          this.updated.discount_value = this.collab.attrs.discount_value
        }

      } catch (err) {
        let summary = 'Error al obtener colaboración'
        let detail = null

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 1000});
      }
    },
    backToIndex() {
      this.$router.push('/collaborations')
    },
    backToShow() {
      this.$router.push(`/collaborations/${this.collab.data.id}`)
    },
    async update() {
      if (!confirm('Seguro desea actualizar?')) {
        return;
      }

      if (this.dateType === 'range') {
        if (!this.range_date) {
          delete this.updated.start_at
          delete this.updated.end_at
        } else {
          this.updated.start_at = moment(this.range_date[0]).format('YYYY-MM-DD HH:mm:00')
          this.updated.end_at = moment(this.range_date[1]).format('YYYY-MM-DD HH:mm:00')
        }
      } else if (this.dateType === 'same_day') {
        if (!this.same_day_date) {
          delete this.updated.start_at
          delete this.updated.end_at
        } else {
          this.updated.start_at = moment(this.same_day_date).format('YYYY-MM-DD HH:mm')
          this.updated.end_at = this.updated.start_at
        }
      }

      try {
        await this.$repository.collaborations
            .update(this.collab.data.id, {...this.updated, ...{ discount_value: this.updated.discount_type === 'fixed_e2' ? this.updated.discount_value * 100 : this.updated.discount_value }})

        this.$toast.add({
          severity: 'success',
          summary: `Collab actualizada`,
          detail: "Redireccionando a detalles",
          life: 1000
        });

        setTimeout(() =>  { this.backToShow() }, 1200)

      } catch (err) {
        let summary = 'Error al actualizar colaboración'
        let detail = null
        let code = null

        if ('response' in err) {
          code = err.response.data?.error_code
          summary = code ? `${code} - ${err.response.data?.message}` : err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 3000});
      }
    }
  },
  mounted() {
    this.getCollab();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.name === from.name) {
        this.getCollab();
      }
    },
    'updated.discount_value'(value) {
      console.log(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.p-chip.active-collab {
  background: green;
  color: var(--primary-color-text);
}

.p-chip.canceled-collab {
  background: red;
  color: var(--primary-color-text);
}

.p-chip.closed-collab {
  background: gray;
  color: var(--primary-color-text);
}
</style>